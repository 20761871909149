import { patchState, signalStoreFeature, withMethods } from "@ngrx/signals";
import { ApiService } from "../../api/services";
import { inject } from "@angular/core";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { catchError, exhaustMap, map, of, switchMap, tap } from "rxjs";
import { FunctionsStore } from "../functions/functions.store";
import { setAllEntities } from "@ngrx/signals/entities";
import { EventTypesStore } from "../event-types/event-types.store";
import { SchemasStore } from "../schemas/schemas.store";
import { TDataCacheDB } from "../../pages/script/data-cache-db.types";
import { DataCacheDbController } from "../../pages/script/data-cache-db.controller";


export function withScenarioConstructorDataMethods() {
    let cacheName: string = 'apiScenarioConstructorDataGet';
    return signalStoreFeature(
        withMethods((
            store: any,
            apiService: ApiService = inject(ApiService),
            functionsStore = inject(FunctionsStore),
            eventTypesStore = inject(EventTypesStore),
            schemasStore = inject(SchemasStore),
        ) => ({
            load: rxMethod<void>(
                exhaustMap(() => {
                    let dataCacheDbController = new DataCacheDbController();
                    return dataCacheDbController.readDbObservable(cacheName).pipe(
                        map(data => ({
                            data,
                            isDataValid: DataCacheDbController.checkCecheName(data, cacheName) && DataCacheDbController.checkCecheTime(data.created)
                        })),
                        switchMap(({ data, isDataValid }) => {
                            if (isDataValid) {
                                return of(Object.assign(JSON.parse(data.text), {type: 'cache'}));
                            } else {
                                return apiService.apiScenarioConstructorDataGet().pipe(
                                    catchError(error => {
                                        return of(Object.assign(JSON.parse(data.text), {type: 'cache'}));
                                    })
                                );
                            }
                        }),
                        tap({
                            next: ({variables, functions, event_types, schemas, type}) => {
                                if (!type) {
                                    dataCacheDbController.writeDb({functions, event_types, variables, schemas}, cacheName);
                                }
                                patchState(functionsStore, setAllEntities(functions));
                                patchState(functionsStore, { isLoaded: true });
                                patchState(eventTypesStore, setAllEntities(event_types));
                                patchState(eventTypesStore, { isLoaded: true });
                                patchState(store, setAllEntities(variables));
                                patchState(store, { isLoaded: true });
                                patchState(schemasStore, setAllEntities(schemas));
                                patchState(schemasStore, { isLoaded: true });
                            },
                            error: console.error,
                        })
                    );
                })
            ),
        })),
    )
}
